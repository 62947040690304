import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Company } from "../../../api/companyAPI";
import { employerOnboardingStep1 } from "../../../redux/actions/onboardingActions";
import { selectOnboardingCompany } from "../../../redux/selectors/onboardingSelectors";
import { styleTextField } from "../../../styles/mui/styleFormControlLabel";
import {
  muiStylesButtons,
  muiStylesFormControlLabel,
} from "../../CompanySettings/muiStylesCompanySettings";
import { companySettingsSchema } from "../../CompanySettings/schema";
import { muiStylesForm } from "./muiStyles";

const useInputStyles = makeStyles(muiStylesFormControlLabel);
const useStyles = makeStyles(muiStylesForm);
const useButtonStyles = makeStyles(muiStylesButtons);
const useTextFieldStyles = makeStyles(styleTextField);

export const EmployerOnboardingStep1: FC = () => {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const buttonClasses = useButtonStyles();
  const textFieldClasses = useTextFieldStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectOnboardingCompany);
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm<Company>({
    mode: "onChange",
    resolver: yupResolver(companySettingsSchema),
    defaultValues: company,
  });
  const submit = handleSubmit((data) => {
    dispatch(employerOnboardingStep1(data));
  });

  return (
    <>
      <div className={classes.pageHeader}>
        <h3>Let’s get started</h3>
        <p>Make sure your building information looks good.</p>
      </div>
      <form
        id="onboarding-company-settings-form"
        className={classes.partialForm}
        onSubmit={submit}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              labelPlacement="top"
              label="Building Name"
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.address}
                  helperText={errors?.address?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              labelPlacement="top"
              label="Address"
            />
          )}
        />
        <Controller
          control={control}
          name="domain"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.domain}
                  helperText={errors?.domain?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              labelPlacement="top"
              label="Main Contact"
            />
          )}
        />
        <div className={classes.button}>
          <Button
            type="submit"
            form="onboarding-company-settings-form"
            className={buttonClasses.primaryButton}
            disabled={!isValid || isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};
