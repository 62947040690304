import * as queryString from "query-string";
import {
  selectIsEmployeeOnboarding,
  selectOnboardingLoading,
  selectOnboardingUserHasBeenFetched,
} from "../redux/selectors/onboardingSelectors";
import { useDispatch, useSelector } from "react-redux";
import AppSnackbarProvider from "../components/Snackbar/SnackbarProvider";
import Loader from "../components/AppLoader";
import Notifier from "../components/Snackbar/Notifier";
import OnboardLayout from "../components/Layout/OnboardLayout";
import Seo from "../components/seo";
import { EmployeeOnboarding } from "../components/Onboarding/EmployeeOnboarding";
import { EmployerOnboarding } from "../components/Onboarding/EmployerOnboarding";
import { PageProps } from "gatsby";
import { authTokenItemName } from "../utils/auth";
import { fetchUser } from "../redux/actions/onboardingActions";
import { useEffect } from "react";

export default function OnboardingPage({ location }: PageProps) {
  const dispatch = useDispatch();
  const isEmployeeOnboarding = useSelector(selectIsEmployeeOnboarding);
  const isLoading = useSelector(selectOnboardingLoading);
  const hasUserBeenFetched = useSelector(selectOnboardingUserHasBeenFetched);
  const { token } = queryString.parse(location.search);

  // remove stored authToken (if exists) on reaching onboarding to prevent authentication crashes
  useEffect(() => {
    localStorage.removeItem(authTokenItemName);
  }, []);

  useEffect(() => {
    if (token && !Array.isArray(token)) {
      dispatch(fetchUser({ token }));
    }
  }, [dispatch, token]);

  const renderedSwitch = () => {
    switch (isEmployeeOnboarding) {
    case true:
      return (
        <>
          <Seo title="Employee onboarding" />
          <EmployeeOnboarding />
        </>
      );
    case false:
      return (
        <>
          <Seo title="Employer onboarding" />
          <EmployerOnboarding />
        </>
      );
    default:
      return <div />;
    }
  };

  return (
    <>
      <Loader loading={isLoading || !hasUserBeenFetched} />
      <AppSnackbarProvider>
        <Notifier />

        <OnboardLayout size="md" progress>
          {renderedSwitch()}
        </OnboardLayout>
      </AppSnackbarProvider>
    </>
  );
}
