// extracted by mini-css-extract-plugin
export var h1Typography = "employeeStepOne-module--h1-typography--31093";
export var h2Typography = "employeeStepOne-module--h2-typography--37gEK";
export var h3Typography = "employeeStepOne-module--h3-typography--10EDO";
export var h4Typography = "employeeStepOne-module--h4-typography--1JlEw";
export var h5Typography = "employeeStepOne-module--h5-typography--2ukgZ";
export var h6Typography = "employeeStepOne-module--h6-typography--vNcis";
export var pTypography = "employeeStepOne-module--p-typography--2v7o6";
export var info = "employeeStepOne-module--info--2FFXf";
export var buttonTypography = "employeeStepOne-module--button-typography--ic7ZG";
export var formLabelTypography = "employeeStepOne-module--form-label-typography--1JQC2";
export var menuTabTypography = "employeeStepOne-module--menu-tab-typography--25x_H";
export var linkStyle = "employeeStepOne-module--link-style--2pRix";
export var postTypography = "employeeStepOne-module--post-typography--2RW_Q";
export var container = "employeeStepOne-module--container--2QDbQ";
export var circleContainer = "employeeStepOne-module--circle-container--3QW3d";