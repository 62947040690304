import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { useEffect } from "react";
import { FC, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PartialUser } from "../../../api/onboardingAPI";
import {
  employerOnboardingStep2,
  setCurrentStep,
  verifyPhoneNumber,
} from "../../../redux/actions/onboardingActions";
import {
  selectOnboardingEmployerError,
  selectOnboardingIsPhoneNumberValid,
  selectOnboardingIsPhoneNumberValidating,
  selectOnboardingLoading,
  selectOnboardingProgress,
  selectOnboardingToken,
  selectOnboardingUser,
} from "../../../redux/selectors/onboardingSelectors";
import { styleTextField } from "../../../styles/mui/styleFormControlLabel";
import {
  muiStylesButtons,
  muiStylesFormControlLabel,
} from "../../CompanySettings/muiStylesCompanySettings";
import { muiStylesForm } from "./muiStyles";
import { employerSchema } from "./schema";

const useInputStyles = makeStyles(muiStylesFormControlLabel);
const useStyles = makeStyles(muiStylesForm);
const useButtonStyles = makeStyles(muiStylesButtons);
const useTextFieldStyles = makeStyles(styleTextField);

export const EmployerOnboardingStep2: FC = () => {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const buttonClasses = useButtonStyles();
  const textFieldClasses = useTextFieldStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<PartialUser | undefined>(undefined);

  const loading = useSelector(selectOnboardingLoading);
  const user = useSelector(selectOnboardingUser);
  const progress = useSelector(selectOnboardingProgress);
  const token = useSelector(selectOnboardingToken);
  const isPhoneNumberValid = useSelector(selectOnboardingIsPhoneNumberValid);
  const isPhoneNumberValidating = useSelector(
    selectOnboardingIsPhoneNumberValidating
  );
  const backendError = useSelector(selectOnboardingEmployerError);
  const back = useCallback(() => {
    if (progress) {
      dispatch(setCurrentStep(progress?.currentStep - 1));
    }
  }, [dispatch, progress]);
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting, dirtyFields },
    control,
    setError,
  } = useForm<PartialUser>({
    mode: "onChange",
    resolver: yupResolver(employerSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
    },
  });
  const submit = handleSubmit((data) => {
    if (token && (!isPhoneNumberValid || dirtyFields.phoneNumber)) {
      setFormData(data);
      dispatch(verifyPhoneNumber({ token, ...data }));
    }

    if (isPhoneNumberValid && !dirtyFields.phoneNumber) {
      dispatch(employerOnboardingStep2(data));
    }
  });

  useEffect(() => {
    if (!loading && isPhoneNumberValid && isPhoneNumberValidating && formData) {
      dispatch(employerOnboardingStep2(formData));
    }
  }, [
    dispatch,
    isPhoneNumberValid,
    isPhoneNumberValidating,
    loading,
    formData,
  ]);

  useEffect(() => {
    if (backendError.error) {
      if (
        backendError.message === "Validation failed" &&
        backendError.details &&
        "phoneNumber" in backendError.details
      ) {
        setError("phoneNumber", {
          message: backendError.details.phoneNumber.join(" "),
        });
      } else {
        // TODO: handle errors
        // eslint-disable-next-line no-console
        console.log(backendError);
      }
    }
  }, [backendError, setError]);

  return (
    <>
      <div className={classes.pageHeader}>
        <h3>We’ll need some information about you</h3>
        <p>
          This is the information you’ll use to log in and that will show up on
          your profile.
        </p>
      </div>
      <form
        id="onboarding-employer-settings-form"
        className={classes.partialForm}
        onSubmit={submit}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.firstName}
                  helperText={errors?.firstName?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              className={classes.leftInput}
              labelPlacement="top"
              label="First Name"
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.lastName}
                  helperText={errors?.lastName?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              className={classes.rightInput}
              labelPlacement="top"
              label="Last Name"
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type="text"
                  error={!!errors.phoneNumber}
                  helperText={errors?.phoneNumber?.message}
                  variant="filled"
                  InputProps={{ className: textFieldClasses.textField }}
                  value={formValue}
                  fullWidth
                />
              }
              classes={inputClasses}
              labelPlacement="top"
              label="Phone number"
            />
          )}
        />
        <FormControlLabel
          control={
            <TextField
              disabled
              type="text"
              variant="filled"
              InputProps={{ className: textFieldClasses.textField }}
              value={user?.email}
              fullWidth
            />
          }
          classes={inputClasses}
          labelPlacement="top"
          label="Email"
        />

        <div className={classes.buttons}>
          <Button
            onClick={back}
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            type="submit"
            form="onboarding-employer-settings-form"
            className={buttonClasses.primaryButton}
            disabled={!isValid || isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};
