import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Input,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  muiStylesButtons,
  muiStylesDialog,
  muiStylesFormControlLabel,
} from "../../../OrganizationStructure/muiStylesOrganizationStructure";
import { onboardingAddUpdateTeamSchema } from "./validationSchemas";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);
const useInputStyles = makeStyles(muiStylesFormControlLabel);

export interface TeamFormData {
  departmentName: string;
  name: string;
}

interface OnboardingAddTeamDialogProps {
  departmentName: string;
  teamName?: string;
  isOpen: boolean;
  onClose: () => void;
  onTeamSubmit: (data: TeamFormData) => void;
}

export const OnboardingAddTeamDialog: FC<OnboardingAddTeamDialogProps> = ({
  departmentName,
  teamName,
  isOpen,
  onClose,
  onTeamSubmit,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();
  const inputClasses = useInputStyles();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<TeamFormData>({
    mode: "onChange",
    resolver: yupResolver(onboardingAddUpdateTeamSchema),
  });

  useEffect(() => {
    setValue("name", teamName || "");
  }, [setValue, teamName]);

  const addTeamSubmit = handleSubmit((data) => {
    onTeamSubmit(data);
    reset();
  });

  const close = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5> {!teamName ? "Add" : "Edit"} department</h5>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="add-department-form" onSubmit={addTeamSubmit}>
          <Controller
            control={control}
            name="departmentName"
            defaultValue={departmentName}
            render={({ field: { value: formValue, ref } }) => (
              <Input value={formValue} ref={ref} type="hidden" />
            )}
          />
          <Controller
            control={control}
            name="name"
            defaultValue={teamName || ""}
            render={({ field: { onChange, value: formValue, ref } }) => (
              <FormControlLabel
                control={
                  <TextField
                    ref={ref}
                    onChange={onChange}
                    type="text"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="filled"
                    value={formValue}
                    fullWidth
                  />
                }
                classes={inputClasses}
                labelPlacement="top"
                label="Department name"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-department-form"
          className={buttonClasses.primaryButton}
        >
          {!teamName ? "Add" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
