import { createStyles } from "@material-ui/core/styles";
import { borderColor } from "../../../../styles/mui/theme";

export const muiStylesAccordionOrganizationStructure = () =>
  createStyles({
    root: {
      "borderStyle": "solid",
      "borderWidth": "1px 0 0 0",
      "borderColor": borderColor,
      "&$expanded": {
        marginBottom: 0,
        marginTop: 0,
        borderStyle: "solid",
        borderWidth: "1px 0 0 0",
        borderColor,
      },
      "&:last-child": {
        borderBottom: 0,
      },
    },
    expanded: {},
  });
