import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { muiStylesFormControlLabel as muiStylesFormControlLabel } from "../../../CompanySettings/muiStylesCompanySettings";
import { styleTextField as muiStylesTextField } from "../../../../styles/mui/styleFormControlLabel";
import { useForm } from "react-hook-form";
import { NewPassword } from "../../../../api/onboardingAPI";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingToken } from "../../../../redux/selectors/onboardingSelectors";
import {
  employeeOnboarding,
  employeeOnboardingStep3,
} from "../../../../redux/actions/onboardingActions";
import { schema } from "../../../ResetPassword/ResetPassword/schemas";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as styles from "../../../ResetPassword/reset-password.module.scss";
import ValidationState from "../../../ValidationState";
import { selectAuthUserDummy } from "../../../../redux/selectors/authSelectors";
import { muiStylesButtons } from "../../../CompanySettings/muiStylesCompanySettings";
import muiStyles from "../muiStylesEmployeeOnboarding";

const useButtonStyles = makeStyles(muiStylesButtons);
const useStyles = makeStyles(muiStyles);
const useStylesInput = makeStyles(muiStylesFormControlLabel);
const useTextFieldStyles = makeStyles(muiStylesTextField);

const EmployeeStepThree: FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const inputClasses = useStylesInput();
  const textFieldClasses = useTextFieldStyles();

  const dispatch = useDispatch();

  const token = useSelector(selectOnboardingToken);
  const authUser = useSelector(selectAuthUserDummy);

  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
    setError,
  } = useForm<NewPassword>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    criteriaMode: "all",
  });

  useEffect(() => {
    setError("newPassword", { types: { min: "min", matches: "matches" } });
  }, [setError]);

  // finish current onboarding step, if user created their password
  useEffect(() => {
    if (authUser) {
      dispatch(employeeOnboardingStep3());
    }
  }, [dispatch, authUser]);

  const onSubmit = handleSubmit((data): void => {
    if (token) {
      dispatch(employeeOnboarding({ token, ...data }));
    }
  });

  return (
    <div>
      <h3>Create a Password</h3>
      <p className={styles.paragraph}>
        Your password must be at least 8 characters long and contain at least
        one number
      </p>
      <form id="onboarding-employee-password-form" onSubmit={onSubmit}>
        <Controller
          control={control}
          name="newPassword"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              classes={inputClasses}
              labelPlacement="top"
              label="PASSWORD"
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type={showPassword ? "text" : "password"}
                  value={formValue}
                  variant="filled"
                  fullWidth
                  InputProps={{
                    className: textFieldClasses.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          )}
        />
        <p
          className={`${styles.description} ${styles.smallFont} ${styles.marginBottom0}`}
        >
          <ValidationState
            error={"min" in (errors?.newPassword?.types || {})}
          />
          8 characters or more
        </p>
        <p className={`${styles.description} ${styles.smallFont}`}>
          <ValidationState
            error={"matches" in (errors?.newPassword?.types || {})}
          />
          One Number and One Letter
        </p>
        <div className={classes.button}>
          <Button
            className={buttonClasses.primaryButton}
            disabled={!isValid || isSubmitting}
            form="onboarding-employee-password-form"
            type="submit"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeStepThree;
