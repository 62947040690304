import React, { FC } from "react";

interface Props {
  item: { icon: string; text: string };
}

const OnboardingCircleIcon: FC<Props> = ({ item }) => {
  return (
    <div>
      <img alt="icon onboarding" src={item.icon} />
      <p>{item.text}</p>
    </div>
  );
};

export default OnboardingCircleIcon;
