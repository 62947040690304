// extracted by mini-css-extract-plugin
export var h1Typography = "GDPROnboarding-module--h1-typography--30rxu";
export var h2Typography = "GDPROnboarding-module--h2-typography--3G_jo";
export var h3Typography = "GDPROnboarding-module--h3-typography--155BK";
export var h4Typography = "GDPROnboarding-module--h4-typography--3fzy6";
export var h5Typography = "GDPROnboarding-module--h5-typography--33N3x";
export var h6Typography = "GDPROnboarding-module--h6-typography--oNzm_";
export var pTypography = "GDPROnboarding-module--p-typography--AgEZl";
export var info = "GDPROnboarding-module--info--3KeQF";
export var buttonTypography = "GDPROnboarding-module--button-typography--19PZ3";
export var formLabelTypography = "GDPROnboarding-module--form-label-typography--1vwI5";
export var menuTabTypography = "GDPROnboarding-module--menu-tab-typography--BW_AE";
export var linkStyle = "GDPROnboarding-module--link-style--3wUIg";
export var postTypography = "GDPROnboarding-module--post-typography--3luK_";
export var container = "GDPROnboarding-module--container--1zhVn";
export var smallButtonContainer = "GDPROnboarding-module--small-button-container--1wD98";
export var email = "GDPROnboarding-module--email--3jEFA";