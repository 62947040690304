import * as yup from "yup";

const requiredNotEmptyString = yup
  .string()
  .min(1)
  .required("This field is required");

export const employerSchema = yup.object().shape({
  firstName: requiredNotEmptyString,
  lastName: requiredNotEmptyString,
  phoneNumber: requiredNotEmptyString,
});
