import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Hidden,
  Input,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, {
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateDepartament,
  OnboardingDepartament,
} from "../../../api/departmentsAPI";
import {
  employerOnboardingStep4,
  setCurrentStep,
} from "../../../redux/actions/onboardingActions";
import {
  selectOnboardingOrganizationStructure,
  selectOnboardingProgress,
} from "../../../redux/selectors/onboardingSelectors";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";
import { muiStylesForm } from "./muiStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { AddTeamButton } from "../../OrganizationStructure/utils";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DepartmentMenu } from "../../OrganizationStructure/departmentMenu";
import { TeamMenu } from "../../OrganizationStructure/teamMenu";
import { OnboardingAddDepartmentDialog } from "./OnboardingOrganizationStructure/addDepartmentDialog";
import { muiStylesOrganizationStructure } from "../../OrganizationStructure/muiStylesOrganizationStructure";
import {
  OnboardingAddTeamDialog,
  TeamFormData,
} from "./OnboardingOrganizationStructure/addTeamDialog";
import { dispatchEnqueueSnackbar } from "../../../redux/actions/notificationsActions";
import { muiStylesAccordionOrganizationStructure } from "./OnboardingOrganizationStructure/muiStylesOnboardingOrganizationStructure";
import { RemoveDialog } from "../../OrganizationStructure/removeDialog";

const useStyles = makeStyles(muiStylesForm);
const useButtonStyles = makeStyles(muiStylesButtons);
const useStructureStyles = makeStyles(muiStylesOrganizationStructure);
const useAccordionStyles = makeStyles(muiStylesAccordionOrganizationStructure);

export const EmployerOnboardingStep4: FC = () => {
  const classes = useStyles();
  const structureClasses = useStructureStyles();
  const buttonClasses = useButtonStyles();
  const accordionClasses = useAccordionStyles();
  const dispatch = useDispatch();

  const [isAddDepartmentOpened, setIsAddDepartmentOpened] = useState(false);
  const [isAddTeamOpened, setIsAddTeamOpened] = useState(false);
  const [isRemoveDepartmentOpened, setIsRemoveDepartmentOpened] = useState(
    false
  );
  const [isRemoveTeamOpened, setIsRemoveTeamOpened] = useState(false);
  const [dialogDepartmentName, setDialogDepartmentName] = useState<string>("");
  const [dialogTeamName, setDialogTeamName] = useState<string | undefined>(
    undefined
  );
  const [organizationStructure, setOrganizationStructure] = useState<
    OnboardingDepartament[]
  >([]);

  const structure = useSelector(selectOnboardingOrganizationStructure);
  const progress = useSelector(selectOnboardingProgress);

  const back = useCallback(() => {
    if (progress) {
      dispatch(setCurrentStep(progress?.currentStep - 1));
    }
  }, [dispatch, progress]);
  const skip = useCallback(() => {
    if (progress) {
      dispatch(setCurrentStep(progress?.currentStep + 1));
    }
  }, [dispatch, progress]);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
  } = useForm<{ structure: OnboardingDepartament[] }>({
    defaultValues: { structure },
  });

  // initialize organization structure on Back/Next actions
  useEffect(() => {
    setOrganizationStructure(structure);
  }, []);

  useEffect(() => {
    setValue("structure", organizationStructure);
  }, [setValue, organizationStructure]);

  const openAddTeam = (departmentName: string) => (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogTeamName(undefined);
    setDialogDepartmentName(departmentName);
    setIsAddTeamOpened(true);
    event.stopPropagation();
  };

  const removeDepartmentHandler = () => {
    setOrganizationStructure(
      organizationStructure.filter(
        (department) => department.name !== dialogDepartmentName
      )
    );
  };

  const removeTeamHandler = () => {
    setOrganizationStructure(
      organizationStructure.map((department) => {
        if (department.name !== dialogDepartmentName) {
          return department;
        }

        return {
          ...department,
          teams: department.teams.filter(
            (team) => team.name !== dialogTeamName
          ),
        };
      })
    );
  };

  const addDepartmentHandler = ({ name }: CreateDepartament) => {
    if (organizationStructure.find((department) => department.name === name)) {
      // if a department is already defined skip adding the team and notify the user
      dispatchEnqueueSnackbar({ message: "Company already exists" });
    } else {
      setOrganizationStructure([...organizationStructure, { name, teams: [] }]);
    }
    setIsAddDepartmentOpened(false);
  };

  const addUpdateTeamHandler = ({ name, departmentName }: TeamFormData) => {
    if (
      organizationStructure.find((department) => {
        return department.teams.find((team) => team.name === name);
      })
    ) {
      // if a team is already defined skip adding the team and notify the user
      dispatchEnqueueSnackbar({
        message: `${name} department already exists.`,
      });
    } else {
      setOrganizationStructure(
        organizationStructure.map((department) => {
          if (department.name !== departmentName) {
            // if added/modified team is not in the current department - return
            return department;
          }

          // if the team is added to the department
          if (!dialogTeamName) {
            return {
              name: departmentName,
              teams: department.teams
                ? [...department.teams, { name }]
                : [{ name }],
            };
          }

          // if the team is modified within the department
          return {
            name: departmentName,
            teams: department.teams.map((team) => {
              if (team.name !== dialogTeamName) {
                return team;
              }

              return { name };
            }),
          };
        })
      );
    }

    setDialogTeamName(undefined);
    setIsAddTeamOpened(false);
  };

  const submit = handleSubmit((data) => {
    if (data.structure) {
      dispatch(employerOnboardingStep4(data.structure));
    }
  });

  return (
    <>
      <OnboardingAddDepartmentDialog
        isOpen={isAddDepartmentOpened}
        onClose={() => {
          setIsAddDepartmentOpened(false);
        }}
        onDepartmentSubmit={addDepartmentHandler}
      />
      <OnboardingAddTeamDialog
        isOpen={isAddTeamOpened}
        teamName={dialogTeamName}
        departmentName={dialogDepartmentName}
        onClose={() => {
          setDialogTeamName(undefined);
          setIsAddTeamOpened(false);
        }}
        onTeamSubmit={addUpdateTeamHandler}
      />
      <RemoveDialog
        isOpen={isRemoveDepartmentOpened}
        onClose={() => setIsRemoveDepartmentOpened(false)}
        action={() => removeDepartmentHandler()}
        header={`Remove company, "${dialogDepartmentName}"?`}
        description={
          "All departments within this company will also be removed. This cannot be undone."
        }
      />
      <RemoveDialog
        isOpen={isRemoveTeamOpened}
        onClose={() => setIsRemoveTeamOpened(false)}
        action={() => removeTeamHandler()}
        header={`Remove department, "${dialogTeamName || ""}"?`}
        description={"This department will be removed. This cannot be undone."}
      />
      <div className={classes.pageHeader}>
        <h3>Organization Structure</h3>
        <Hidden smUp implementation="css">
          <p>
            Please use a desktop computer or laptop to create and edit the
            Tenancy Schedule.
          </p>
        </Hidden>
        <Hidden xsDown implementation="css">
          <p>
            Add Companies and Departments to organize your building’s structure.
            You can always skip this step and edit the structure later.
          </p>
        </Hidden>
      </div>

      <form
        id="onboarding-employer-organization-structure-form"
        className={classes.partialForm}
        onSubmit={submit}
      >
        {
          <Controller
            control={control}
            name="structure"
            render={({ field: { value: formValue, ref } }) => (
              <Hidden xsDown implementation="css">
                <Input ref={ref} value={formValue} type="hidden" />
                <div className={structureClasses.root}>
                  {formValue.length
                    ? formValue.map(({ name: department, teams }) => (
                        <Accordion
                          key={`${department}`}
                          classes={accordionClasses}
                        >
                          <AccordionSummary
                            className={structureClasses.accordionSummary}
                            expandIcon={<ArrowDropDownIcon />}
                            IconButtonProps={{ edge: "start" }}
                            aria-controls={`panel-${department}-content`}
                            id={`panel-${department}-header`}
                          >
                            <Typography className={structureClasses.heading}>
                              {department}
                            </Typography>
                            <Typography
                              className={structureClasses.secondaryHeading}
                            >
                              {`${teams.length} ${
                                teams.length === 1
                                  ? "Department"
                                  : "Departments"
                              }`}
                            </Typography>
                            <AddTeamButton
                              color="inherit"
                              size="small"
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={openAddTeam(department)}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              Add Department
                            </AddTeamButton>
                            <DepartmentMenu
                              removeDepartment={() => {
                                setDialogDepartmentName(department);
                                setIsRemoveDepartmentOpened(true);
                              }}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            className={structureClasses.accordionDetailsLevel1}
                          >
                            {teams.map(({ name: team }) => (
                              <div
                                className={
                                  structureClasses.onboardingTeamContainer
                                }
                                key={`${team}`}
                                id={`panel-${team}-header`}
                              >
                                <Typography
                                  className={`${structureClasses.innerHeading} ${structureClasses.onboardingTeamHeader}`}
                                >
                                  {`${team}`}
                                </Typography>
                                <TeamMenu
                                  removeTeam={() => {
                                    setDialogDepartmentName(department);
                                    setDialogTeamName(team);
                                    setIsRemoveTeamOpened(true);
                                  }}
                                  editTeam={() => {
                                    setDialogDepartmentName(department);
                                    setDialogTeamName(team);
                                    setIsAddTeamOpened(true);
                                  }}
                                />
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    : null}
                </div>
                <Button
                  onClick={() => {
                    setIsAddDepartmentOpened(true);
                  }}
                  className={clsx(
                    buttonClasses.secondaryButton,
                    classes.addDepartmentButton
                  )}
                  variant="outlined"
                >
                  Add a Company
                </Button>
              </Hidden>
            )}
          />
        }
        <div
          className={clsx(
            classes.buttons,
            classes.organizationStructureButtons
          )}
        >
          <Button
            onClick={back}
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            onClick={skip}
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Skip
          </Button>
          <Hidden mdDown implementation="css">
            <Button
              type="submit"
              form="onboarding-employer-organization-structure-form"
              className={buttonClasses.primaryButton}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Hidden>
        </div>
      </form>
    </>
  );
};
