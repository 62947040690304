import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NewPassword } from "../../../api/onboardingAPI";
import {
  employerOnboardingStep3,
  setCurrentStep,
} from "../../../redux/actions/onboardingActions";
import {
  selectOnboardingNewPassword,
  selectOnboardingProgress,
} from "../../../redux/selectors/onboardingSelectors";
import { styleTextField } from "../../../styles/mui/styleFormControlLabel";
import {
  muiStylesButtons,
  muiStylesFormControlLabel,
} from "../../CompanySettings/muiStylesCompanySettings";
import * as styles from "../../ResetPassword/reset-password.module.scss";
import { schema } from "../../ResetPassword/ResetPassword/schemas";
import ValidationState from "../../ValidationState";
import { muiStylesForm } from "./muiStyles";

const useStyles = makeStyles(muiStylesForm);
const useTextFieldStyles = makeStyles(styleTextField);
const useButtonStyles = makeStyles(muiStylesButtons);
const useInputStyles = makeStyles(muiStylesFormControlLabel);

export const EmployerOnboardingStep3: FC = () => {
  const classes = useStyles();
  const textFieldClasses = useTextFieldStyles();
  const inputClasses = useInputStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();
  const password = useSelector(selectOnboardingNewPassword);
  const progress = useSelector(selectOnboardingProgress);
  const back = useCallback(() => {
    if (progress) {
      dispatch(setCurrentStep(progress?.currentStep - 1));
    }
  }, [dispatch, progress]);

  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    control,
  } = useForm<NewPassword>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    criteriaMode: "all",
    defaultValues: password,
  });

  useEffect(() => {
    if (!password.newPassword) {
      setError("newPassword", { types: { min: "min", matches: "matches" } });
    }
  }, []);

  const submit = handleSubmit((data): void => {
    dispatch(employerOnboardingStep3(data.newPassword));
  });

  return (
    <>
      <div className={classes.pageHeader}>
        <h3>Create a Password</h3>
        <p>
          Your password must be at least 8 characters long and contain at least
          one number.
        </p>
      </div>
      <form
        id="onboarding-employer-password-form"
        className={classes.partialForm}
        onSubmit={submit}
      >
        {/* for some mystical reason the div below is required for the form validation to work properly in production build... */}
        <div />
        <Controller
          control={control}
          name="newPassword"
          render={({ field: { onChange, value: formValue, ref } }) => (
            <FormControlLabel
              control={
                <TextField
                  ref={ref}
                  onChange={onChange}
                  type={showPassword ? "text" : "password"}
                  error={!!errors.newPassword}
                  variant="filled"
                  fullWidth
                  value={formValue}
                  InputProps={{
                    className: textFieldClasses.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
              classes={inputClasses}
              className={classes.passwordInput}
              labelPlacement="top"
              label="PASSWORD"
            />
          )}
        />
        <p
          className={`${styles.description} ${styles.smallFont} ${styles.marginBottom0}`}
        >
          <ValidationState
            error={"min" in (errors?.newPassword?.types || {})}
          />
          8 characters or more
        </p>
        <p className={`${styles.description} ${styles.smallFont}`}>
          <ValidationState
            error={"matches" in (errors?.newPassword?.types || {})}
          />
          One Number and One Letter
        </p>

        <div className={classes.buttons}>
          <Button
            onClick={back}
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            type="submit"
            form="onboarding-employer-password-form"
            className={buttonClasses.primaryButton}
            disabled={!isValid || isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};
