import * as yup from "yup";

export const onboardingAddDepartmentSchema = yup.object().shape({
  name: yup.string().min(1).required("This field is required"),
});

export const onboardingAddUpdateTeamSchema = yup.object().shape({
  departmentName: yup.string().min(1).required("This field is required"),
  name: yup.string().min(1).required("This field is required"),
});
