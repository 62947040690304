import { Theme, createStyles } from "@material-ui/core";
import {
  fontColorTertiary,
  secondaryColorHover,
} from "../../../styles/mui/theme";

export const muiStylesForm = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    pageHeader: {
      "padding": `28px 20px 8px`,
      "& p": {
        color: fontColorTertiary,
      },
      "& h3": {
        marginBottom: "1rem",
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    partialForm: {
      padding: `0 20px 28px 20px`,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
    },
    buttons: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingTop": "24px",
      "& .MuiButton-root": {
        padding: "6px 40px",
        marginLeft: "8px",
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
      },
      "& > .MuiButton-outlined": {
        marginLeft: 0,
        padding: "6px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0.75rem",
        marginTop: "auto",
      },
    },
    button: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "flex-end",
      "paddingTop": "24px",
      "& .MuiButton-root": {
        padding: "6px 40px",
        marginLeft: "8px",
        [theme.breakpoints.down("sm")]: {
          margin: 0,
          width: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0.75rem",
        marginTop: "auto",
      },
    },
    leftInput: {
      [theme.breakpoints.up("md")]: {
        width: "49%",
        marginRight: "2%",
      },
    },
    rightInput: {
      [theme.breakpoints.up("md")]: {
        width: "49%",
      },
    },
    passwordInput: {
      [theme.breakpoints.down("sm")]: {
        "&.MuiFormControlLabel-root": { paddingBottom: "8px" },
      },
    },
    bulkInfo: {
      "text-align": "center",
      "padding": "20px",
      "margin": "0 20px 20px",
      "backgroundColor": secondaryColorHover,
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "& > p": {
        margin: 0,
        color: fontColorTertiary,
      },
      "& .MuiButtonBase-root": {
        width: "147px",
        height: "40px",
        flexShrink: 0,
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          margin: "8px 0 0 0",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        margin: 0,
      },
    },
    addDepartmentButton: {
      width: "100%",
      padding: "19px 15px",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    organizationStructureButtons: {
      "& > .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          width: "calc(50% - 4px)",
        },
      },
    },
  });
