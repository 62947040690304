// extracted by mini-css-extract-plugin
export var h1Typography = "employeeStepTwo-module--h1-typography--3dIds";
export var h2Typography = "employeeStepTwo-module--h2-typography--2Y1Si";
export var h3Typography = "employeeStepTwo-module--h3-typography--2Yp3w";
export var h4Typography = "employeeStepTwo-module--h4-typography--2LYS5";
export var h5Typography = "employeeStepTwo-module--h5-typography--3SD1O";
export var h6Typography = "employeeStepTwo-module--h6-typography--3hoYj";
export var pTypography = "employeeStepTwo-module--p-typography--HgWSf";
export var info = "employeeStepTwo-module--info--3n4Mx";
export var buttonTypography = "employeeStepTwo-module--button-typography--vtNq8";
export var formLabelTypography = "employeeStepTwo-module--form-label-typography--2D62e";
export var menuTabTypography = "employeeStepTwo-module--menu-tab-typography--1m5DC";
export var linkStyle = "employeeStepTwo-module--link-style--2iOOd";
export var postTypography = "employeeStepTwo-module--post-typography--3_sRc";
export var adminEmail = "employeeStepTwo-module--admin-email--3uzgt";
export var paragraph = "employeeStepTwo-module--paragraph--34_aW";