import * as styles from "./employeeStepOne.module.scss";
import { Button, FormControl, Hidden, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import IconWorkout from "../../../../icons/onboarding/workout.svg";
import IconReward from "../../../../icons/onboarding/reward.svg";
import IconBond from "../../../../icons/onboarding/bond.svg";
import OnboardingCircleIcon from "./OnboardingCircleIcon";
import { useDispatch } from "react-redux";
import { employeeOnboardingStep1 } from "../../../../redux/actions/onboardingActions";
import { muiStylesButtons } from "../../../CompanySettings/muiStylesCompanySettings";
import Carousel from "react-material-ui-carousel";

const onboadringCricleIcons = [
  { icon: IconWorkout, text: "Workout when you want" },
  { icon: IconBond, text: "Build Relationships" },
  { icon: IconReward, text: "Elevate your potential" },
];

const useButtonStyles = makeStyles(muiStylesButtons);

const EmployeeStepOne: FC = () => {
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();

  const handleNextStep = () => {
    dispatch(employeeOnboardingStep1());
  };

  return (
    <div className={styles.container}>
      <h3>Welcome to MOTKO</h3>
      <p>
        We believe in the power of sport to change the world. To make us
        healthier and happier, to build strong and united communities and to
        inspire change.
      </p>
      <Hidden lgUp implementation="css">
        <div className={styles.circleContainer}>
          <Carousel autoPlay navButtonsAlwaysInvisible interval={3000}>
            {onboadringCricleIcons.map((circle) => (
              <OnboardingCircleIcon key={circle.text} item={circle} />
            ))}
          </Carousel>
        </div>
      </Hidden>
      <Hidden mdDown implementation="css">
        <div className={styles.circleContainer}>
          {onboadringCricleIcons.map((circle) => (
            <OnboardingCircleIcon key={circle.text} item={circle} />
          ))}
        </div>
      </Hidden>

      <FormControl>
        <Button
          className={buttonClasses.primaryButton}
          onClick={handleNextStep}
          type="submit"
        >
          Get Started
        </Button>
      </FormControl>
    </div>
  );
};

export default EmployeeStepOne;
