// extracted by mini-css-extract-plugin
export var h1Typography = "employeeOnboarding-module--h1-typography--Eo93s";
export var h2Typography = "employeeOnboarding-module--h2-typography--2RKVH";
export var h3Typography = "employeeOnboarding-module--h3-typography--3lEql";
export var h4Typography = "employeeOnboarding-module--h4-typography--3fNuX";
export var h5Typography = "employeeOnboarding-module--h5-typography--2e-jv";
export var h6Typography = "employeeOnboarding-module--h6-typography--2A8MJ";
export var pTypography = "employeeOnboarding-module--p-typography--3wHYh";
export var info = "employeeOnboarding-module--info--3ThLH";
export var buttonTypography = "employeeOnboarding-module--button-typography--2AE5q";
export var formLabelTypography = "employeeOnboarding-module--form-label-typography--2BCdV";
export var menuTabTypography = "employeeOnboarding-module--menu-tab-typography--35FqR";
export var linkStyle = "employeeOnboarding-module--link-style--3xg1z";
export var postTypography = "employeeOnboarding-module--post-typography--3hlLR";
export var container = "employeeOnboarding-module--container--_BhLo";
export var paragraph = "employeeOnboarding-module--paragraph--3O0Fx";