import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { CreateDepartament } from "../../../../api/departmentsAPI";

import {
  muiStylesButtons,
  muiStylesDialog,
  muiStylesFormControlLabel,
} from "../../../OrganizationStructure/muiStylesOrganizationStructure";
import { onboardingAddDepartmentSchema } from "./validationSchemas";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);
const useInputStyles = makeStyles(muiStylesFormControlLabel);

interface OnboardingAddDepartmentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDepartmentSubmit: (data: CreateDepartament) => void;
}

export const OnboardingAddDepartmentDialog: FC<OnboardingAddDepartmentDialogProps> = ({
  isOpen,
  onClose,
  onDepartmentSubmit,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();
  const inputClasses = useInputStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateDepartament>({
    mode: "onChange",
    resolver: yupResolver(onboardingAddDepartmentSchema),
  });
  const addDepartmentSubmit = handleSubmit((data) => {
    onDepartmentSubmit(data);
    reset();
  });

  const close = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>Add company</h5>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="add-department-form" onSubmit={addDepartmentSubmit}>
          <FormControlLabel
            control={
              <TextField
                {...register("name")}
                type="text"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="filled"
                fullWidth
              />
            }
            classes={inputClasses}
            labelPlacement="top"
            label="Company name"
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-department-form"
          className={buttonClasses.primaryButton}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
