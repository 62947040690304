import { Button, Paper, makeStyles } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employerOnboardingStep5 } from "../../../redux/actions/authActions";
import {
  setCurrentStep,
  showWelcomeMessage,
  usersOnboarding,
} from "../../../redux/actions/onboardingActions";
import {
  selectOnboardingEmployer,
  selectOnboardingLogin,
  selectOnboardingProgress,
  selectOnboardingToken,
} from "../../../redux/selectors/onboardingSelectors";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";
import { muiStylesForm } from "./muiStyles";
import { bulkUpload } from "../../../redux/actions/bulkUploadActions";
import { dispatchEnqueueSnackbar } from "../../../redux/actions/notificationsActions";
import { getProperCSVFile } from "../../../utils";

const bulkUploadUrl = `${process.env.API_URL ?? ""}/api/users/bulk_upload/`;
const useStyles = makeStyles(muiStylesForm);
const useButtonStyles = makeStyles(muiStylesButtons);

export const EmployerOnboardingStep5: FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();

  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);

  const onboardingEmployer = useSelector(selectOnboardingEmployer);
  const progress = useSelector(selectOnboardingProgress);
  const token = useSelector(selectOnboardingToken);
  const login = useSelector(selectOnboardingLogin);

  const back = useCallback(() => {
    if (progress) {
      dispatch(setCurrentStep(progress?.currentStep - 1));
    }
  }, [dispatch, progress]);

  const finish = () => {
    if (token) {
      dispatch(usersOnboarding({ token, ...onboardingEmployer }));
      dispatch(showWelcomeMessage());
    }
  };

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);

    const reader = new FileReader();

    reader.onload = () => {
      const content = reader.result as string;
      const properFile = getProperCSVFile(file, content);

      if (properFile) {
        setCsvFile(properFile);
        dispatchEnqueueSnackbar({
          message: `Selected file: ${file?.name || ""}`,
        });
      } else {
        dispatchEnqueueSnackbar({
          message:
            "Error: The file is not recognized as csv or file's headers are incorrect.",
        });
      }
    };

    file && reader.readAsText(file);

    // clear selected file to enable the reload
    event.target.value = "";
    event.target.files = null;
  };

  useEffect(() => {
    if (login) {
      if (csvFile) {
        dispatch(bulkUpload(csvFile));
      }
      dispatch(employerOnboardingStep5(login));
    }
  }, [dispatch, csvFile, login]);

  return (
    <>
      <div className={classes.pageHeader}>
        <h3>Time to Add Occupants</h3>
        <p>
          Use the CSV upload to quickly add all of your occupants. You can
          always skip this step and do it later.
        </p>
      </div>
      <Paper elevation={0} className={classes.bulkInfo}>
        <p>Download the form over here.</p>
        <Button
          href={bulkUploadUrl}
          download
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Download CSV
        </Button>
      </Paper>
      <Paper elevation={0} className={classes.bulkInfo}>
        <p>Upload the filled in form.</p>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => selectFile(e)}
          />
          <Button component="span" className={buttonClasses.primaryButton}>
            Upload CSV
          </Button>
        </label>
      </Paper>
      <div className={classes.partialForm}>
        <div className={classes.buttons}>
          <Button
            onClick={back}
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Back
          </Button>
          <Button onClick={finish} className={buttonClasses.primaryButton}>
            Finish
          </Button>
        </div>
      </div>
    </>
  );
};
