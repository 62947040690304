import * as styles from "./GDPROnboarding.module.scss";
import { Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useDispatch } from "react-redux";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";
import { onboardingGDPR } from "../../../redux/actions/onboardingActions";
import muiStyles from "../EmployeeOnboarding/muiStylesEmployeeOnboarding";
import clsx from "clsx";
import { useIsSmall } from "../../../utils";

const useButtonStyles = makeStyles(muiStylesButtons);
const useStyles = makeStyles(muiStyles);
const PrivacyPolicyLocation = "/privacy_policy/MotkoPrivacyPolicy.pdf";

const GDPR: FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();
  const isSmall = useIsSmall();

  const handleNextStep = () => {
    dispatch(onboardingGDPR());
  };

  return (
    <div className={styles.container}>
      <p>
        By proceeding with the registration, you agree to our
        <a href={PrivacyPolicyLocation} download="Motko Privacy Policy">
          Privacy Policy
        </a>
        .
      </p>

      <div
        className={
          isSmall
            ? clsx(classes.button, styles.smallButtonContainer)
            : classes.button
        }
      >
        <Button
          className={buttonClasses.primaryButton}
          type="submit"
          onClick={handleNextStep}
        >
          I AGREE
        </Button>
      </div>
    </div>
  );
};

export default GDPR;
