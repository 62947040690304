import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsSmall } from "../../../utils";
import { setSteps } from "../../../redux/actions/onboardingActions";
import { selectOnboardingProgress } from "../../../redux/selectors/onboardingSelectors";
import { EmployerOnboardingStep1 } from "./step1";
import { EmployerOnboardingStep2 } from "./step2";
import { EmployerOnboardingStep3 } from "./step3";
import { EmployerOnboardingStep4 } from "./step4";
import { EmployerOnboardingStep5 } from "./step5";
import GDPR from "../GDPROnboarding";
import * as styles from "./employerOnboarding.module.scss";

export const EmployerOnboarding: FC = () => {
  const isSmall = useIsSmall();
  const dispatch = useDispatch();
  const progress = useSelector(selectOnboardingProgress);

  useEffect(() => {
    if (!progress?.steps) {
      dispatch(setSteps(6));
    }
  }, [dispatch, progress?.steps]);

  if (!progress) {
    return null;
  }

  switch (progress.currentStep) {
  case 1:
    return <GDPR />;
  case 2:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployerOnboardingStep1 />
      </div>
    );
  case 3:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployerOnboardingStep2 />
      </div>
    );
  case 4:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployerOnboardingStep3 />
      </div>
    );
  case 5:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployerOnboardingStep4 />
      </div>
    );
  case 6:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployerOnboardingStep5 />
      </div>
    );

  default:
    return null;
  }
};
