import * as styles from "./employeeOnboarding.module.scss";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeStepFour from "./EmployeeStepFour";
import EmployeeStepOne from "./EmployeeStepOne";
import EmployeeStepThree from "./EmployeeStepThree";
import EmployeeStepTwo from "./EmployeeStepTwo";
import GDPR from "../GDPROnboarding";
import { selectOnboardingProgress } from "../../../redux/selectors/onboardingSelectors";
import { setSteps } from "../../../redux/actions/onboardingActions";
import { useIsSmall } from "../../../utils";

export const EmployeeOnboarding: FC = () => {
  const isSmall = useIsSmall();
  const dispatch = useDispatch();
  const progress = useSelector(selectOnboardingProgress);

  useEffect(() => {
    if (!progress?.steps) {
      dispatch(setSteps(5));
    }
  }, [dispatch, progress?.steps]);

  if (!progress) {
    return null;
  }

  switch (progress.currentStep) {
  case 1:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployeeStepOne />
      </div>
    );
  case 2:
    return <GDPR />;
  case 3:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployeeStepTwo />
      </div>
    );
  case 4:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployeeStepThree />
      </div>
    );
  case 5:
    return (
      <div className={isSmall ? styles.container : ""}>
        <EmployeeStepFour />
      </div>
    );
  default:
    return null;
  }
};
