import * as styles from "../employeeOnboarding.module.scss";
import React, { FC, useEffect } from "react";
import ActivityPreferences from "../../../AccountSettings/ActivityPreferences";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import { selectActivityPreferencesProcessed } from "../../../../redux/selectors/activityPreferencesSelectors";
import { navigate } from "gatsby";
import { loginRedirectTo } from "../../../../utils/auth";
import { showWelcomeMessage } from "../../../../redux/actions/onboardingActions";

const EmployeeStepFour: FC = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const preferredActivitiesSubmitted = useSelector(
    selectActivityPreferencesProcessed
  );

  useEffect(() => {
    if (preferredActivitiesSubmitted) {
      dispatch(showWelcomeMessage());
      void navigate(loginRedirectTo);
    }
  }, [dispatch, preferredActivitiesSubmitted]);

  return (
    <div>
      <h3>Choose Activities</h3>
      <p className={styles.paragraph}>
        Let co-workers know which activities they should invite you to!
      </p>
      <div>
        {authUser ? (
          <ActivityPreferences onboarding userId={authUser.id} />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default EmployeeStepFour;
